const StampSVG: React.FC<{ width?: string, height?: string, color?: string }> = ({ width = '179', height = '57', color = '#0D77BB' }) => {
    return <>
        <svg width={width} height={height} viewBox="0 0 179 57" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.31068 11.1996C7.31068 13.3966 5.9516 14.7698 4.0489 14.7698C2.14619 14.7698 0.787109 13.122 0.787109 11.1996V10.1011H2.14619V10.925C2.14619 12.2981 2.96163 13.3966 4.0489 13.3966C5.13616 13.3966 5.95161 12.5728 5.95161 10.925V6.25635H7.31068V11.1996Z" fill={color} />
            <path d="M11.3878 13.122H10.0287C8.94142 13.122 8.12598 12.5728 8.12598 11.4743V6.25635H9.48505V10.6504C9.48505 11.4743 9.75687 11.7489 10.8441 11.7489H11.3878V13.122Z" fill={color} />
            <path d="M18.4549 13.1221H16.824C16.2804 13.6714 15.7367 13.946 14.9213 13.946C14.1058 13.946 13.2904 13.6714 13.0186 13.1221H11.3877V11.749H12.475C12.475 10.1012 13.2904 9.27734 14.9213 9.27734C16.5522 9.27734 17.3676 10.1012 17.3676 11.749H18.4549V13.1221ZM15.7367 11.749C15.7367 10.9251 15.4649 10.3759 14.9213 10.3759C14.3777 10.3759 14.1058 10.9251 14.1058 11.4744C14.1058 12.2982 14.3777 12.5729 15.1931 12.5729C15.4649 12.8475 15.7367 12.2982 15.7367 11.749Z" fill={color} />
            <path d="M23.8911 13.1225H17.9111V11.7493H19.542C19.2702 11.4747 19.2702 10.9255 19.2702 10.6508C19.2702 9.82696 19.542 9.2777 19.8138 9.00308C20.3575 8.45382 20.9011 8.1792 21.4447 8.1792C22.2602 8.1792 23.0756 8.72845 23.3474 9.55233L22.2602 10.1016C21.9884 9.55233 21.7165 9.55233 21.4447 9.55233C21.1729 9.55233 20.9011 9.55233 20.6293 9.82696C20.3575 10.1016 20.3575 10.3762 20.3575 10.6508C20.3575 11.2001 20.6293 11.4747 20.9011 11.7493H23.8911V13.1225Z" fill={color} />
            <path d="M26.8812 6.25644H24.9785V5.98181H25.5221V5.70718C25.5221 5.15793 25.794 4.8833 26.3376 4.8833C26.6094 4.8833 26.8812 5.15793 27.153 5.43256L26.6094 5.70718C26.6094 5.43256 26.6094 5.43256 26.3376 5.43256C26.0658 5.43256 26.0658 5.43256 26.0658 5.70718C26.0658 5.98181 26.0658 5.98181 26.3376 5.98181H27.153V6.25644H26.8812ZM30.4148 11.4743C30.4148 12.0236 30.143 12.2982 29.8712 12.8475C29.5994 13.1221 29.0557 13.3967 28.5121 13.3967H24.9785V12.0236H26.6094L25.2503 7.35494H26.8812L28.2403 12.0236C28.7839 12.0236 29.0557 11.749 29.0557 10.9251V6.25644H30.4148V11.4743Z" fill={color} />
            <path d="M32.861 13.122H31.502V6.25635H32.861V13.122Z" fill={color} />
            <path d="M44.5493 11.4744C44.5493 12.5729 44.0057 13.1221 42.6466 13.1221H38.8412C36.3949 13.1221 35.3076 12.0236 35.3076 10.1012V8.17886H36.6667V10.1012C36.6667 10.6505 36.9385 11.1998 37.2103 11.4744C37.4821 11.749 38.0258 11.749 38.5694 11.749H41.2876C41.8312 11.749 42.103 11.749 42.103 11.749C42.3748 11.4744 42.6466 11.1998 42.6466 10.6505V8.45349H44.0057V11.4744H44.5493ZM39.6567 8.45349H38.2976V7.35498H39.6567V8.45349ZM41.8312 8.45349H40.4721V7.35498H41.8312V8.45349Z" fill={color} />
            <path d="M48.627 13.122H47.2679C46.1807 13.122 45.3652 12.5728 45.3652 11.4743V6.25635H46.7243V10.6504C46.7243 11.4743 46.9961 11.7489 48.0834 11.7489H48.627V13.122Z" fill={color} />
            <path d="M54.6062 11.7491C54.6062 13.1222 53.7908 14.2207 52.1599 14.2207C51.3445 14.2207 50.529 13.9461 50.2572 13.3968H48.3545V12.0237H49.7136C49.7136 10.3759 50.529 9.55204 52.1599 9.55204C53.7908 9.27742 54.6062 10.1013 54.6062 11.7491ZM53.2472 11.7491C53.2472 10.9252 52.9754 10.3759 52.4317 10.3759C51.8881 10.3759 51.6163 10.9252 51.6163 11.4744C51.6163 12.2983 51.8881 12.5729 52.7035 12.5729C52.9754 12.8476 53.2472 12.2983 53.2472 11.7491Z" fill={color} />
            <path d="M62.4887 13.1225H55.6934V11.7493H59.4988C59.4988 10.3762 58.6833 9.55233 56.7806 9.55233V8.1792C57.5961 8.1792 58.4115 8.1792 58.6833 8.45382C60.0424 9.00308 60.8578 10.1016 60.8578 11.7493H62.4887V13.1225Z" fill={color} />
            <path d="M70.6434 13.122H61.9453V11.7489H67.9253C67.9253 11.1996 67.6534 10.6504 67.3816 10.3758C66.838 9.8265 66.2944 9.55187 65.7507 9.55187C65.2071 9.55187 64.3916 9.8265 64.1198 10.3758C63.848 10.1011 63.848 9.8265 63.3044 9.27724C64.1198 8.45336 64.9353 8.17874 66.0225 8.17874C66.5662 8.17874 67.1098 8.17874 67.6534 8.45336C68.7407 9.00262 69.5561 10.1011 69.5561 11.7489H70.6434V13.122ZM66.5662 7.62948H65.2071V6.25635H66.5662V7.62948Z" fill={color} />
            <path d="M73.6339 11.1996C73.6339 11.7489 73.362 12.2981 73.0902 12.5728C72.8184 13.122 72.2748 13.122 71.7311 13.122H70.3721V11.7489H71.4593C72.003 11.7489 72.2748 11.4743 72.2748 10.6504V6.25635H73.6339V11.1996Z" fill={color} />
            <path d="M81.2446 10.9254H79.07C77.9828 10.9254 77.4392 11.2 77.4392 12.0239C77.4392 12.8478 77.9828 13.1224 79.07 13.1224H80.1573V14.4955H79.07C76.8955 14.4955 76.0801 13.397 76.0801 11.7492C76.0801 10.3761 76.8955 9.27761 78.2546 9.00298C78.5264 9.00298 79.07 9.00298 79.8855 9.00298C79.6137 8.1791 79.07 7.62984 78.2546 7.62984C77.9828 7.62984 77.711 7.62984 77.711 7.62984V6.25671C77.9828 6.25671 78.2546 6.25671 78.5264 6.25671C80.4291 6.25671 81.7882 7.62984 81.7882 9.82686C81.2446 10.6507 81.2446 10.9254 81.2446 10.9254ZM79.8855 6.25671H78.5264V5.1582H79.8855V6.25671Z" fill={color} />
            <path d="M85.3213 13.122H83.9623C82.875 13.122 82.0596 12.5728 82.0596 11.4743V6.25635H83.6905V10.6504C83.6905 11.4743 83.9623 11.7489 85.0495 11.7489H85.5932V13.122H85.3213Z" fill={color} />
            <path d="M92.3885 13.1221H90.7576C90.214 13.6714 89.6703 13.946 88.8549 13.946C88.0395 13.946 87.224 13.6714 86.9522 13.1221H85.3213V11.749H86.4086C86.4086 10.1012 87.224 9.27734 88.8549 9.27734C90.4858 9.27734 91.3012 10.1012 91.3012 11.749H92.6603V13.1221H92.3885ZM89.6703 11.749C89.6703 10.9251 89.3985 10.3759 88.8549 10.3759C88.3113 10.3759 88.0394 10.9251 88.0394 11.4744C88.0394 12.2982 88.3113 12.5729 89.1267 12.5729C89.3985 12.8475 89.6703 12.2982 89.6703 11.749Z" fill={color} />
            <path d="M101.359 10.6507C101.359 12.0238 100.272 13.1223 99.1847 13.1223H94.5639H91.8457V11.7492H93.2048C93.7484 11.7492 94.0202 11.4746 94.0202 10.6507V8.17904H95.3793V10.376C95.3793 10.6507 95.3793 11.1999 95.1075 11.4746H95.9229C96.4666 11.4746 96.7384 11.1999 96.7384 10.6507V8.17904H98.0975V10.376C98.0975 10.6507 98.0975 11.1999 97.8256 11.4746H98.6411C99.1847 11.4746 99.4565 11.1999 99.4565 10.6507V8.17904H100.816V10.6507H101.359ZM97.282 7.62978H95.9229V6.25665H97.282V7.62978ZM98.3693 5.70739H97.0102V4.60889H98.3693V5.70739ZM99.1847 7.62978H97.8256V6.25665H99.1847V7.62978Z" fill={color} />
            <path d="M105.708 13.122H104.349C103.262 13.122 102.446 12.5728 102.446 11.4743V6.25635H103.805V10.6504C103.805 11.4743 104.077 11.7489 105.164 11.7489H105.708V13.122Z" fill={color} />
            <path d="M108.698 11.2001C108.698 11.7493 108.426 12.2986 108.155 12.5732C107.883 13.1225 107.339 13.1225 106.796 13.1225H105.437V11.7493H106.252C106.796 11.7493 107.067 11.4747 107.067 10.6508V8.1792H108.426V11.2001H108.698ZM108.426 15.0449H107.067V13.9464H108.426V15.0449Z" fill={color} />
            <path d="M118.211 13.1225H111.416V11.7493H115.221C115.221 10.3762 114.406 9.55233 112.503 9.55233V8.1792C113.319 8.1792 114.134 8.1792 114.406 8.45382C115.765 9.00308 116.581 10.1016 116.581 11.7493H118.211V13.1225Z" fill={color} />
            <path d="M125.278 13.1221H123.647C123.104 13.6714 122.56 13.946 121.745 13.946C120.929 13.946 120.114 13.6714 119.842 13.1221H118.211V11.749H119.026C119.026 10.1012 119.842 9.27734 121.473 9.27734C123.104 9.27734 123.919 10.1012 123.919 11.749H125.278V13.1221ZM122.56 11.749C122.56 10.9251 122.288 10.3759 121.745 10.3759C121.201 10.3759 120.929 10.9251 120.929 11.4744C120.929 12.2982 121.201 12.5729 122.016 12.5729C122.016 12.8475 122.56 12.2982 122.56 11.749Z" fill={color} />
            <path d="M132.346 13.1225H124.735V11.7493H130.715C130.715 10.6508 129.628 9.55233 128.541 9.55233C127.997 9.55233 127.182 9.82696 126.638 10.3762C126.366 10.1016 126.366 9.82696 125.823 9.27771C126.638 8.45383 127.454 8.1792 128.541 8.1792C130.987 8.1792 132.074 9.82696 132.074 12.5732V13.1225H132.346Z" fill={color} />
            <path d="M135.064 5.70733H133.161V5.15808H133.705V4.88345C133.705 4.3342 133.977 4.05957 134.52 4.05957C134.792 4.05957 135.064 4.3342 135.336 4.60882L134.792 4.88345C134.792 4.60882 134.792 4.60882 134.52 4.60882C134.248 4.60882 134.248 4.60882 134.248 4.88345C134.248 5.15808 134.248 5.15808 134.52 5.15808H135.336V5.70733H135.064ZM134.792 13.1223H133.433V6.25658H134.792V13.1223Z" fill={color} />
            <path d="M144.306 13.1224H142.947C142.132 13.1224 141.588 12.5731 141.044 11.7492C140.773 12.2985 140.229 12.2985 139.685 12.2985C138.326 12.2985 137.511 11.2 137.511 9.82686C137.511 8.1791 138.326 7.35522 139.685 7.35522C139.685 7.35522 140.501 7.35522 142.403 7.35522V10.6507C142.403 11.4746 142.675 11.7492 143.491 11.7492H144.306V13.1224ZM139.957 6.25671H138.598V5.1582H139.957V6.25671ZM141.044 9.82686V8.45372H139.957C139.413 8.45372 138.87 9.00298 138.87 9.82686C138.87 10.6507 139.413 11.2 139.957 11.2C140.501 11.2 141.044 10.6507 141.044 9.82686ZM141.86 6.25671H140.773V5.1582H142.132V6.25671H141.86Z" fill={color} />
            <path d="M150.014 13.1225H144.034V11.7493H145.665C145.393 11.4747 145.393 10.9255 145.393 10.6508C145.393 9.82696 145.665 9.2777 145.937 9.00308C146.481 8.45382 147.024 8.1792 147.568 8.1792C148.383 8.1792 149.199 8.72845 149.47 9.55233L148.383 10.1016C148.111 9.55233 147.84 9.55233 147.568 9.55233C147.296 9.55233 147.024 9.55233 146.752 9.82696C146.481 10.1016 146.481 10.3762 146.481 10.6508C146.481 11.2001 146.752 11.4747 147.024 11.7493H149.47V13.1225H150.014Z" fill={color} />
            <path d="M156.809 13.1225H155.45C155.178 14.221 154.363 14.7702 153.004 14.7702H152.732V13.6717C153.276 13.6717 153.548 13.3971 153.819 13.1225C153.819 13.1225 153.276 13.1225 153.004 13.1225C151.645 13.1225 150.558 12.024 150.558 10.6508C150.558 9.27771 151.645 8.1792 153.004 8.1792C154.363 8.1792 155.45 9.27771 155.45 10.6508V11.7493H156.809V13.1225ZM154.091 11.7493V10.6508C154.091 10.1016 153.819 9.55233 153.276 9.55233C152.732 9.55233 152.46 10.1016 152.46 10.6508C152.46 11.2001 152.732 11.7493 153.276 11.7493H154.091Z" fill={color} />
            <path d="M163.877 13.1221H162.246C161.702 13.6714 161.159 13.946 160.343 13.946C159.528 13.946 158.712 13.6714 158.44 13.1221H156.81V11.749H157.897C157.897 10.1012 158.712 9.27734 160.343 9.27734C161.974 9.27734 162.79 10.1012 162.79 11.749H164.149V13.1221H163.877ZM161.159 11.749C161.159 10.9251 160.887 10.3759 160.343 10.3759C159.8 10.3759 159.528 10.9251 159.528 11.4744C159.528 12.2982 159.8 12.5729 160.615 12.5729C160.887 12.8475 161.159 12.2982 161.159 11.749Z" fill={color} />
            <path d="M172.303 13.1225H163.604V11.7493H169.584C169.584 11.2001 169.313 10.6508 169.041 10.3762C168.497 9.82696 167.954 9.55233 167.41 9.55233C166.866 9.55233 166.051 9.82696 165.779 10.3762C165.507 10.1016 165.507 9.82696 164.964 9.27771C165.779 8.45383 166.594 8.1792 167.682 8.1792C168.225 8.1792 168.769 8.1792 169.313 8.45382C170.4 9.00308 171.215 10.1016 171.215 11.7493H172.574V13.1225H172.303ZM168.225 15.0449H166.866V13.9464H168.225V15.0449Z" fill={color} />
            <path d="M178.011 11.7491C178.011 13.1222 177.195 14.2207 175.564 14.2207C174.749 14.2207 173.933 13.9461 173.661 13.3968H171.759V12.0237H173.118C173.118 10.3759 173.933 9.55204 175.564 9.55204C177.195 9.27742 178.011 10.1013 178.011 11.7491ZM176.651 11.7491C176.651 10.9252 176.38 10.3759 175.836 10.3759C175.292 10.3759 175.021 10.9252 175.021 11.4744C175.021 12.2983 175.292 12.5729 176.108 12.5729C176.108 12.8476 176.651 12.2983 176.651 11.7491Z" fill={color} />
            <path d="M7.58279 32.0713H4.86464L4.59283 33.1698H2.14648L4.86464 25.7549H7.31098L10.0291 33.1698H7.85461L7.58279 32.0713ZM7.03916 30.4235L6.22372 27.9519L5.40827 30.4235H7.03916Z" fill={color} />
            <path d="M17.9116 25.7549V33.1698H15.7371V30.1489H13.2907V33.1698H11.1162V25.7549H13.2907V28.5012H15.7371V25.7549H17.9116Z" fill={color} />
            <path d="M28.5126 25.7549V33.1698H26.338V29.0504L24.979 33.1698H23.3481L21.989 29.0504V33.1698H19.8145V25.7549H22.2608L24.1635 30.6982L26.0662 25.7549H28.5126Z" fill={color} />
            <path d="M32.3177 27.4026V28.5012H34.7641V30.1489H32.3177V31.522H35.0359V33.1698H30.415V25.7549H35.0359V27.4026H32.3177Z" fill={color} />
            <path d="M41.56 26.3041C42.1036 26.5788 42.6473 27.128 42.9191 27.6773C43.1909 28.2265 43.4627 28.7758 43.4627 29.5997C43.4627 30.4235 43.1909 30.9728 42.9191 31.522C42.6473 32.0713 42.1036 32.6206 41.56 32.8952C41.0164 33.1698 40.2009 33.4444 39.3855 33.4444H36.3955V25.7549H39.3855C40.2009 25.7549 41.0164 26.0295 41.56 26.3041ZM40.7446 30.9728C41.0164 30.6982 41.2882 30.1489 41.2882 29.5997C41.2882 29.0504 41.0164 28.5012 40.7446 28.2265C40.4727 27.9519 39.9291 27.6773 39.3855 27.6773H38.57V31.522H39.3855C39.9291 31.522 40.4727 31.2474 40.7446 30.9728Z" fill={color} />
            <path d="M53.2475 30.1489C53.5193 30.4235 53.5193 30.9728 53.5193 31.2474C53.5193 31.7967 53.2475 32.3459 52.9757 32.6206C52.7039 32.8952 51.8884 33.1698 51.073 33.1698H47.2676V25.7549H51.073C51.8884 25.7549 52.4321 26.0295 52.9757 26.3041C53.5193 26.5788 53.5193 27.128 53.5193 27.6773C53.5193 28.2265 53.5193 28.5012 53.2475 28.7758C52.9757 29.0504 52.7039 29.325 52.4321 29.325C52.7039 29.5997 52.9757 29.5997 53.2475 30.1489ZM49.4421 28.7758H50.5294C51.073 28.7758 51.3448 28.5012 51.3448 28.2265C51.3448 27.6773 51.073 27.6773 50.5294 27.6773H49.4421V28.7758ZM51.6166 30.9728C51.6166 30.6982 51.6166 30.6982 51.3448 30.4235C51.3448 30.4235 51.073 30.1489 50.8012 30.1489H49.7139V31.522H50.8012C51.3448 31.522 51.6166 31.2474 51.6166 30.9728Z" fill={color} />
            <path d="M59.7709 32.0713H57.0528L56.781 33.1698H54.6064L57.3246 25.7549H59.7709L62.4891 33.1698H60.3146L59.7709 32.0713ZM59.2273 30.4235L58.4119 27.9519L57.5964 30.4235H59.2273Z" fill={color} />
            <path d="M64.3926 32.6206C63.849 32.0713 63.5771 31.522 63.5771 30.9728H65.7517C65.7517 31.2474 65.7517 31.522 66.0235 31.522C66.0235 31.522 66.2953 31.7967 66.5671 31.7967C66.8389 31.7967 66.8389 31.7967 67.1108 31.522L67.3826 31.2474C67.3826 30.9728 67.3826 30.9728 67.1108 30.6982C66.8389 30.6982 66.5671 30.4235 66.0235 30.4235C65.4799 30.1489 65.208 30.1489 64.9362 29.8743C64.6644 29.5997 64.3926 29.5997 64.1208 29.325C63.849 29.0504 63.849 28.7758 63.849 28.2265C63.849 27.6773 63.849 27.4026 64.1208 26.8534C64.3926 26.5788 64.6644 26.3041 65.208 26.0295C65.7517 25.7549 66.0235 25.7549 66.5671 25.7549C67.3826 25.7549 68.198 26.0295 68.7416 26.3041C69.2853 26.5788 69.5571 27.4026 69.5571 27.9519H67.3826C67.3826 27.6773 67.3826 27.6773 67.1108 27.4026C67.1108 27.4026 66.8389 27.128 66.5671 27.128C66.2953 27.128 66.2953 27.128 66.2953 27.4026L66.0235 27.6773C66.0235 27.9519 66.0235 27.9519 66.2953 27.9519L66.5671 28.2265C66.8389 28.2265 66.8389 28.5012 67.3826 28.5012C67.9262 28.7758 68.198 28.7758 68.4698 29.0504C68.7417 29.325 69.0135 29.325 69.2853 29.5997C69.5571 29.8743 69.5571 30.1489 69.5571 30.6982C69.5571 31.2474 69.5571 31.522 69.2853 31.7967C69.0135 32.0713 68.7416 32.3459 68.198 32.6206C67.6544 32.8952 67.3826 32.8952 66.8389 32.8952C65.4799 33.4444 64.9362 33.1698 64.3926 32.6206Z" fill={color} />
            <path d="M77.4389 25.7549V33.1698H75.5362V30.1489H72.8181V33.1698H70.6436V25.7549H72.8181V28.5012H75.5362V25.7549H77.4389Z" fill={color} />
            <path d="M83.9633 32.0713H81.2452L80.9733 33.1698H78.7988L81.517 25.7549H83.9633L86.6815 33.1698H84.507L83.9633 32.0713ZM83.4197 30.4235L82.6042 27.9519L81.7888 30.4235H83.4197Z" fill={color} />
            <path d="M96.4666 25.7549V33.1698H94.2921V29.0504L92.9331 33.1698H91.3022L89.9431 29.0504V33.1698H87.7686V25.7549H90.2149L92.1176 30.6982L94.0203 25.7549H96.4666Z" fill={color} />
            <path d="M107.067 25.7549V33.1698H104.893V29.0504L103.534 33.1698H101.903L100.544 29.0504V33.1698H98.3691V25.7549H100.815L102.718 30.6982L104.621 25.7549H107.067Z" fill={color} />
            <path d="M113.319 32.0713H110.601L110.329 33.1698H108.154L110.872 25.7549H113.319L116.037 33.1698H113.862L113.319 32.0713ZM113.047 30.4235L112.232 27.9519L111.416 30.4235H113.047Z" fill={color} />
            <path d="M121.745 33.1698L119.299 29.8743V33.1698H117.124V25.7549H119.299V29.0504L121.745 25.7549H124.191L121.473 29.325L124.463 33.1698H121.745Z" fill={color} />
            <path d="M132.075 25.7549V33.1698H129.9V30.1489H127.454V33.1698H125.279V25.7549H127.454V28.5012H129.9V25.7549H132.075Z" fill={color} />
            <path d="M141.316 28.2265C141.316 27.9518 141.044 27.9518 140.773 27.6772C140.501 27.6772 140.229 27.4026 139.957 27.4026C139.413 27.4026 139.142 27.6772 138.598 27.9518C138.326 28.2265 138.054 28.7757 138.054 29.325C138.054 29.8742 138.326 30.4235 138.598 30.6981C138.87 30.9727 139.413 31.2474 139.957 31.2474C140.773 31.2474 141.316 30.9727 141.588 30.1489H139.413V28.7757H143.219V30.6981C142.947 30.9727 142.947 31.522 142.403 31.7966C142.132 32.0712 141.588 32.3459 141.316 32.6205C140.773 32.8951 140.229 32.8951 139.685 32.8951C138.87 32.8951 138.326 32.6205 137.783 32.3459C137.239 32.0712 136.695 31.522 136.424 30.9727C136.152 30.4235 135.88 29.5996 135.88 29.0503C135.88 28.2265 136.152 27.6772 136.424 27.128C136.695 26.5787 137.239 26.0294 137.783 25.7548C138.326 25.4802 139.142 25.2056 139.685 25.2056C140.501 25.2056 141.316 25.4802 142.132 25.7548C142.675 26.3041 143.219 26.8533 143.219 27.6772H141.316V28.2265Z" fill={color} />
            <path d="M148.927 33.1698L147.567 30.4235H147.296V33.1698H145.121V25.7549H148.383C148.927 25.7549 149.47 25.7549 150.014 26.0295C150.557 26.3041 150.829 26.5788 150.829 26.8534C151.101 27.128 151.101 27.6773 151.101 27.9519C151.101 28.5011 150.829 28.7758 150.557 29.325C150.286 29.5997 149.742 29.8743 149.47 30.1489L151.101 33.1698H148.927ZM147.296 29.0504H148.383C148.655 29.0504 148.927 29.0504 148.927 28.7758C149.198 28.7758 149.198 28.5012 149.198 28.2265C149.198 27.9519 149.198 27.6773 148.927 27.6773C148.655 27.4026 148.655 27.4026 148.383 27.4026H147.296V29.0504Z" fill={color} />
            <path d="M154.364 32.8952C153.82 32.6206 153.276 32.0713 153.005 31.522C152.733 30.9728 152.461 30.1489 152.461 29.5997C152.461 29.0504 152.733 28.2265 153.005 27.6773C153.276 27.128 153.82 26.5788 154.364 26.3041C154.907 26.0295 155.451 25.7549 156.266 25.7549C157.082 25.7549 157.625 26.0295 158.169 26.3041C158.713 26.5788 159.256 27.128 159.528 27.6773C159.8 28.2265 160.072 29.0504 160.072 29.5997C160.072 30.1489 159.8 30.9728 159.528 31.522C159.256 32.0713 158.713 32.6206 158.169 32.8952C157.625 33.1698 157.082 33.4444 156.266 33.4444C155.451 33.4444 154.907 33.1698 154.364 32.8952ZM157.625 30.9728C157.897 30.6982 158.169 30.1489 158.169 29.5997C158.169 29.0504 157.897 28.5012 157.625 28.2265C157.354 27.9519 156.81 27.6773 156.266 27.6773C155.723 27.6773 155.451 27.9519 154.907 28.2265C154.635 28.5012 154.364 29.0504 154.364 29.5997C154.364 30.1489 154.635 30.6982 154.907 30.9728C155.179 31.2474 155.723 31.522 156.266 31.522C156.81 31.2474 157.354 31.2474 157.625 30.9728Z" fill={color} />
            <path d="M163.605 25.7549V30.1489C163.605 30.4235 163.605 30.9728 163.877 30.9728C164.148 30.9728 164.42 31.2474 164.692 31.2474C164.964 31.2474 165.236 31.2474 165.508 30.9728C165.779 30.6982 165.779 30.4235 165.779 30.1489V25.7549H167.954V30.1489C167.954 30.9728 167.682 31.522 167.41 31.7967C167.138 32.3459 166.867 32.6206 166.323 32.8952C165.779 33.1698 165.236 33.1698 164.692 33.1698C164.149 33.1698 163.605 33.1698 163.061 32.8952C162.518 32.6206 162.246 32.3459 161.974 31.7967C161.702 31.2474 161.702 30.6982 161.702 30.1489V25.7549H163.605Z" fill={color} />
            <path d="M175.293 29.5997C175.021 29.8743 174.749 30.1489 174.478 30.4235C173.934 30.6982 173.662 30.6982 172.847 30.6982H171.759V33.1698H169.585V25.7549H172.575C173.119 25.7549 173.662 25.7549 174.206 26.0295C174.749 26.3041 175.021 26.5788 175.021 26.8534C175.293 27.128 175.293 27.6773 175.293 28.2265C175.565 28.7758 175.565 29.0504 175.293 29.5997ZM173.662 28.2265C173.662 27.6773 173.39 27.4026 172.847 27.4026H172.031V29.0504H172.847C173.119 29.0504 173.662 28.7758 173.662 28.2265Z" fill={color} />
            <path d="M21.9887 37.8384V38.3876H20.086V39.7608H21.445V40.31H20.086V42.2324H19.2705V37.8384H21.9887Z" fill={color} />
            <path d="M26.8812 41.9578C26.6094 41.6832 26.3376 41.4085 26.0658 41.1339C25.7939 40.8593 25.7939 40.31 25.7939 40.0354C25.7939 39.7608 25.7939 39.2115 26.0658 38.9369C26.3376 38.6623 26.6094 38.3876 26.8812 38.113C27.153 37.8384 27.6967 37.8384 27.9685 37.8384C28.2403 37.8384 28.7839 37.8384 29.0557 38.113C29.3275 38.3876 29.5994 38.6623 29.8712 38.9369C30.143 39.2115 30.143 39.7608 30.143 40.0354C30.143 40.31 30.143 40.8593 29.8712 41.1339C29.5994 41.4085 29.3275 41.6832 29.0557 41.9578C28.7839 42.2324 28.2403 42.2324 27.9685 42.2324C27.4248 42.2324 27.153 42.2324 26.8812 41.9578ZM28.7839 41.4085C29.0557 41.4085 29.0557 41.1339 29.3275 40.8593C29.3275 40.5846 29.5994 40.31 29.5994 40.0354C29.5994 39.7608 29.5994 39.4861 29.3275 39.2115C29.3275 38.9369 29.0557 38.6623 28.7839 38.6623C28.5121 38.6623 28.2403 38.3876 27.9685 38.3876C27.6967 38.3876 27.4248 38.3876 27.153 38.6623C26.8812 38.6623 26.8812 38.9369 26.6094 39.2115C26.6094 39.4861 26.3376 39.7608 26.3376 40.0354C26.3376 40.31 26.3376 40.5846 26.6094 40.8593C26.8812 41.1339 26.8812 41.4085 27.153 41.4085C27.4248 41.4085 27.6967 41.6832 27.9685 41.6832C28.2403 41.6832 28.5121 41.6832 28.7839 41.4085Z" fill={color} />
            <path d="M36.3946 42.2324L35.3073 40.5846H34.7637V42.2324H33.9482V37.8384H35.3073C35.5791 37.8384 35.8509 37.8384 36.1228 38.113C36.3946 38.113 36.3946 38.3876 36.6664 38.6623C36.6664 38.9369 36.9382 39.2115 36.9382 39.2115C36.9382 39.4861 36.9382 39.7608 36.6664 40.0354C36.3946 40.31 36.1228 40.31 35.851 40.5846L36.9382 42.507H36.3946V42.2324ZM34.7637 40.0354H35.5791C35.8509 40.0354 36.1228 40.0354 36.1228 39.7608C36.1228 39.4861 36.3946 39.4861 36.3946 39.2115C36.3946 38.9369 36.3946 38.9369 36.1228 38.6623C36.1228 38.6623 35.8509 38.3876 35.5791 38.3876H34.7637V40.0354Z" fill={color} />
            <path d="M48.8988 40.31C49.1706 40.5846 49.1706 40.8593 49.1706 41.1339C49.1706 41.4085 49.1706 41.6832 48.8988 41.6832C48.8988 41.9578 48.627 41.9578 48.3552 42.2324C48.3552 42.2324 48.0834 42.2324 47.8116 42.2324H46.1807V37.8384H47.8116C48.0834 37.8384 48.3552 37.8384 48.627 38.113C48.8988 38.113 48.8988 38.3876 49.1706 38.3876C49.1706 38.6623 49.4425 38.6623 49.4425 38.9369C49.4425 39.2115 49.4424 39.4861 49.1706 39.4861C49.1706 39.7608 48.8988 39.7608 48.627 39.7608C48.627 40.0354 48.627 40.31 48.8988 40.31ZM46.7243 39.7608H47.5397C47.8116 39.7608 48.0834 39.7608 48.0834 39.4861C48.0834 39.2115 48.3552 39.2115 48.3552 38.9369C48.3552 38.6623 48.3552 38.6623 48.0834 38.3876C47.8116 38.113 47.8116 38.113 47.5397 38.113H46.7243V39.7608ZM48.0834 41.4085C48.0834 41.4085 48.3552 41.1339 48.3552 40.8593C48.3552 40.5846 48.3552 40.5846 48.0834 40.31C47.8116 40.31 47.8116 40.0354 47.5397 40.0354H46.7243V41.4085H47.5397C47.8116 41.6832 48.0834 41.6832 48.0834 41.4085Z" fill={color} />
            <path d="M53.7907 37.8384V40.5846C53.7907 40.8593 53.7907 41.1339 54.0625 41.4085C54.3343 41.6832 54.3343 41.6832 54.878 41.6832C55.1498 41.6832 55.4216 41.6832 55.6934 41.4085C55.9652 41.1339 55.9652 40.8593 55.9652 40.5846V37.8384H56.5089V40.5846C56.5089 40.8593 56.5089 41.1339 56.237 41.4085C55.9652 41.6832 55.9652 41.9578 55.6934 41.9578C55.4216 41.9578 55.1498 42.2324 54.878 42.2324C54.6062 42.2324 54.3343 42.2324 54.0625 41.9578C53.7907 41.9578 53.5189 41.6832 53.5189 41.4085C53.2471 41.1339 53.2471 40.8593 53.2471 40.5846V37.8384H53.7907Z" fill={color} />
            <path d="M61.4024 42.2324C61.1305 42.2324 60.8587 41.9578 60.8587 41.6832C60.8587 41.4085 60.5869 41.4085 60.5869 41.1339H61.4024C61.4024 41.4085 61.4024 41.4085 61.6742 41.6832C61.6742 41.6832 61.946 41.9578 62.2178 41.9578C62.4896 41.9578 62.7614 41.9578 62.7614 41.6832C62.7614 41.6832 63.0333 41.4085 63.0333 41.1339C63.0333 40.8593 63.0333 40.8593 63.0333 40.8593L62.7614 40.5846C62.7614 40.5846 62.4896 40.5846 62.2178 40.31C61.946 40.31 61.6742 40.0354 61.4024 40.0354C61.1305 40.0354 61.1305 39.7608 60.8587 39.7608C60.8587 39.4861 60.5869 39.4861 60.5869 39.2115C60.5869 38.9369 60.5869 38.6623 60.8587 38.6623C60.8587 38.3876 61.1305 38.3876 61.4024 38.113C61.6742 38.113 61.946 37.8384 62.2178 37.8384C62.7614 37.8384 63.0333 37.8384 63.3051 38.113C63.5769 38.3876 63.8487 38.6623 63.8487 38.9369H63.0333C63.0333 38.6623 63.0333 38.6623 62.7614 38.6623C62.7614 38.6623 62.4896 38.3876 62.2178 38.3876C61.946 38.3876 61.946 38.3876 61.6742 38.6623C61.6742 38.6623 61.4024 38.9369 61.4024 39.2115V39.4861L61.6742 39.7608C61.6742 39.7608 61.946 39.7608 62.2178 40.0354C62.4896 40.0354 62.7614 40.31 63.0333 40.31C63.3051 40.31 63.3051 40.5846 63.5769 40.5846C63.8487 40.5846 63.8487 40.8593 63.8487 41.1339C63.8487 41.4085 63.8487 41.6832 63.5769 41.6832C63.5769 41.9578 63.3051 41.9578 63.0333 42.2324C62.7614 42.2324 62.4896 42.2324 61.946 42.2324C61.6742 42.2324 61.4024 42.2324 61.4024 42.2324Z" fill={color} />
            <path d="M68.4688 37.8384V42.2324H67.6533V37.8384H68.4688Z" fill={color} />
            <path d="M76.0798 42.2324H75.2644L73.0899 38.9369V42.2324H72.2744V37.8384H73.0899L75.2644 41.1339V37.8384H76.0798V42.2324Z" fill={color} />
            <path d="M80.9727 38.3876V39.7608H82.3317V40.31H80.9727V41.6832H82.6036V42.2324H80.1572V37.8384H82.6036V38.3876H80.9727Z" fill={color} />
            <path d="M87.4961 42.2324C87.2243 42.2324 86.9525 41.9578 86.9525 41.6832C86.9525 41.4085 86.6807 41.4085 86.6807 41.1339H87.4961C87.4961 41.4085 87.4961 41.4085 87.7679 41.6832C87.7679 41.6832 88.0397 41.9578 88.3116 41.9578C88.5834 41.9578 88.8552 41.9578 88.8552 41.6832C88.8552 41.6832 89.127 41.4085 89.127 41.1339C89.127 40.8593 89.127 40.8593 89.127 40.8593L88.8552 40.5846C88.8552 40.5846 88.5834 40.5846 88.3116 40.31C88.0397 40.31 87.7679 40.0354 87.4961 40.0354C87.2243 40.0354 87.2243 39.7608 86.9525 39.7608C86.9525 39.4861 86.6807 39.4861 86.6807 39.2115C86.6807 38.9369 86.6807 38.6623 86.9525 38.6623C86.9525 38.3876 87.2243 38.3876 87.4961 38.113C87.7679 38.113 88.0397 37.8384 88.3116 37.8384C88.8552 37.8384 89.127 37.8384 89.3988 38.113C89.6706 38.3876 89.9425 38.6623 89.9425 38.9369H89.127C89.127 38.6623 89.127 38.6623 88.8552 38.6623C88.8552 38.6623 88.5834 38.3876 88.3116 38.3876C88.0397 38.3876 88.0397 38.3876 87.7679 38.6623C87.7679 38.6623 87.4961 38.9369 87.4961 39.2115V39.4861L87.7679 39.7608C87.7679 39.7608 88.0397 39.7608 88.3116 40.0354C88.5834 40.0354 88.8552 40.31 89.127 40.31C89.3988 40.31 89.3988 40.5846 89.6706 40.5846C89.9424 40.5846 89.9425 40.8593 89.9425 41.1339C89.9425 41.4085 89.9424 41.6832 89.6706 41.6832C89.6706 41.9578 89.3988 41.9578 89.127 42.2324C88.8552 42.2324 88.5834 42.507 88.3116 42.507C87.7679 42.2324 87.4961 42.2324 87.4961 42.2324Z" fill={color} />
            <path d="M94.292 42.2324C94.0202 42.2324 93.7484 41.9578 93.7484 41.6832C93.7484 41.4085 93.4766 41.4085 93.4766 41.1339H94.292C94.292 41.4085 94.292 41.4085 94.5638 41.6832C94.5638 41.6832 94.8356 41.9578 95.1075 41.9578C95.3793 41.9578 95.6511 41.9578 95.6511 41.6832C95.6511 41.6832 95.9229 41.4085 95.9229 41.1339C95.9229 40.8593 95.9229 40.8593 95.9229 40.8593L95.6511 40.5846C95.6511 40.5846 95.3793 40.5846 95.1075 40.31C94.8356 40.31 94.5638 40.0354 94.292 40.0354C94.0202 40.0354 94.0202 39.7608 93.7484 39.7608C93.7484 39.4861 93.4766 39.4861 93.4766 39.2115C93.4766 38.9369 93.4766 38.6623 93.7484 38.6623C93.7484 38.3876 94.0202 38.3876 94.292 38.113C94.5638 38.113 94.8356 37.8384 95.1075 37.8384C95.6511 37.8384 95.9229 37.8384 96.1947 38.113C96.4665 38.3876 96.7384 38.6623 96.7384 38.9369H95.9229C95.9229 38.6623 95.9229 38.6623 95.6511 38.6623C95.6511 38.6623 95.3793 38.3876 95.1075 38.3876C94.8356 38.3876 94.8356 38.3876 94.5638 38.6623C94.5638 38.6623 94.292 38.9369 94.292 39.2115V39.4861L94.5638 39.7608C94.5638 39.7608 94.8356 39.7608 95.1075 40.0354C95.3793 40.0354 95.6511 40.31 95.9229 40.31C96.1947 40.31 96.1947 40.5846 96.4665 40.5846C96.7383 40.5846 96.7384 40.8593 96.7384 41.1339C96.7384 41.4085 96.7383 41.6832 96.4665 41.6832C96.4665 41.9578 96.1947 41.9578 95.9229 42.2324C95.6511 42.2324 95.3793 42.507 95.1075 42.507C94.8356 42.2324 94.5638 42.2324 94.292 42.2324Z" fill={color} />
            <path d="M105.979 42.2324C105.708 42.2324 105.436 41.9578 105.436 41.6832C105.436 41.4085 105.164 41.4085 105.164 41.1339H105.979C105.979 41.4085 105.98 41.4085 106.251 41.6832C106.251 41.6832 106.523 41.9578 106.795 41.9578C107.067 41.9578 107.339 41.9578 107.339 41.6832C107.339 41.6832 107.61 41.4085 107.61 41.1339C107.61 40.8593 107.61 40.8593 107.61 40.8593L107.339 40.5846C107.339 40.5846 107.067 40.5846 106.795 40.31C106.523 40.31 106.251 40.0354 105.979 40.0354C105.708 40.0354 105.708 39.7608 105.436 39.7608C105.436 39.4861 105.436 39.4861 105.436 39.2115C105.436 38.9369 105.436 38.6623 105.708 38.6623C105.708 38.3876 105.98 38.3876 106.251 38.113C106.523 38.113 106.795 37.8384 107.067 37.8384C107.61 37.8384 107.882 37.8384 108.154 38.113C108.426 38.3876 108.698 38.6623 108.698 38.9369H107.882C107.882 38.6623 107.882 38.6623 107.61 38.6623C107.61 38.6623 107.339 38.3876 107.067 38.3876C106.795 38.3876 106.795 38.3876 106.523 38.6623C106.523 38.6623 106.251 38.9369 106.251 39.2115V39.4861L106.523 39.7608C106.523 39.7608 106.795 39.7608 107.067 40.0354C107.339 40.0354 107.61 40.31 107.882 40.31C108.154 40.31 108.154 40.5846 108.426 40.5846C108.698 40.5846 108.698 40.8593 108.698 41.1339C108.698 41.4085 108.698 41.6832 108.426 41.6832C108.426 41.9578 108.154 41.9578 107.882 42.2324C107.61 42.2324 107.339 42.507 107.067 42.507C106.523 42.2324 106.251 42.2324 105.979 42.2324Z" fill={color} />
            <path d="M113.047 38.3876V39.7608H114.406V40.31H113.047V41.6832H114.678V42.2324H112.231V37.8384H114.678V38.3876H113.047Z" fill={color} />
            <path d="M121.201 42.2324L120.114 40.5846H119.57V42.2324H119.026V37.8384H120.385C120.657 37.8384 120.929 37.8384 121.201 38.113C121.473 38.113 121.473 38.3876 121.745 38.6623C121.745 38.9369 122.016 39.2115 122.016 39.2115C122.016 39.4861 122.016 39.7608 121.745 40.0354C121.473 40.31 121.201 40.31 120.929 40.5846L122.016 42.507H121.201V42.2324ZM119.57 40.0354H120.385C120.657 40.0354 120.929 40.0354 120.929 39.7608C120.929 39.4861 121.201 39.4861 121.201 39.2115C121.201 38.9369 121.201 38.9369 120.929 38.6623C120.929 38.6623 120.657 38.3876 120.385 38.3876H119.57V40.0354Z" fill={color} />
            <path d="M129.899 37.8384L128.269 42.2324H127.453L125.822 37.8384H126.638L127.997 41.4085L129.356 37.8384H129.899Z" fill={color} />
            <path d="M134.248 37.8384V42.2324H133.433V37.8384H134.248Z" fill={color} />
            <path d="M138.597 38.9369C138.869 38.6623 139.141 38.3876 139.412 38.113C139.684 37.8384 140.228 37.8384 140.5 37.8384C141.043 37.8384 141.315 37.8384 141.587 38.113C141.859 38.3876 142.131 38.6623 142.402 39.2115H141.587C141.587 38.9369 141.315 38.9369 141.043 38.6623C140.772 38.6623 140.5 38.3876 140.228 38.3876C139.956 38.3876 139.684 38.3876 139.412 38.6623C139.141 38.6623 139.141 38.9369 138.869 39.2115C138.869 39.4861 138.597 39.7608 138.597 40.0354C138.597 40.31 138.597 40.5846 138.869 40.8593C138.869 41.1339 139.141 41.4085 139.412 41.4085C139.684 41.4085 139.956 41.6832 140.228 41.6832C140.5 41.6832 140.772 41.6832 141.043 41.4085C141.315 41.4085 141.315 41.1339 141.587 40.8593H142.402C142.131 41.4085 141.859 41.6832 141.587 41.6832C141.315 41.6832 141.043 42.2324 140.5 42.2324C140.228 42.2324 139.684 42.2324 139.412 41.9578C139.141 41.6832 138.869 41.4085 138.597 41.1339C138.325 40.8593 138.325 40.31 138.325 40.0354C138.325 39.7608 138.325 39.2115 138.597 38.9369Z" fill={color} />
            <path d="M147.296 38.3876V39.7608H148.655V40.31H147.296V41.6832H148.927V42.2324H146.48V37.8384H148.927V38.3876H147.296Z" fill={color} />
            <path d="M153.547 42.2324C153.275 42.2324 153.003 41.9578 153.003 41.6832C153.003 41.4085 152.731 41.4085 152.731 41.1339H153.547C153.547 41.4085 153.547 41.4085 153.819 41.6832C153.819 41.6832 154.091 41.9578 154.362 41.9578C154.634 41.9578 154.906 41.9578 154.906 41.6832C154.906 41.6832 155.178 41.4085 155.178 41.1339C155.178 40.8593 155.178 40.8593 155.178 40.8593L154.906 40.5846C154.906 40.5846 154.634 40.5846 154.362 40.31C154.091 40.31 153.819 40.0354 153.547 40.0354C153.275 40.0354 153.275 39.7608 153.003 39.7608C153.003 39.4861 152.731 39.4861 152.731 39.2115C152.731 38.9369 152.731 38.6623 153.003 38.6623C153.003 38.3876 153.275 38.3876 153.547 38.113C153.819 38.113 154.091 37.8384 154.362 37.8384C154.906 37.8384 155.178 37.8384 155.45 38.113C155.721 38.3876 155.993 38.6623 155.993 38.9369H155.178C155.178 38.6623 155.178 38.6623 154.906 38.6623C154.906 38.6623 154.634 38.3876 154.362 38.3876C154.091 38.3876 154.091 38.3876 153.819 38.6623C153.819 38.6623 153.547 38.9369 153.547 39.2115V39.4861L153.819 39.7608C153.819 39.7608 154.091 39.7608 154.362 40.0354C154.634 40.0354 154.906 40.31 155.178 40.31C155.45 40.31 155.45 40.5846 155.721 40.5846C155.993 40.5846 155.993 40.8593 155.993 41.1339C155.993 41.4085 155.993 41.6832 155.721 41.6832C155.721 41.9578 155.45 41.9578 155.178 42.2324C154.906 42.2324 154.634 42.2324 154.362 42.2324C154.091 42.2324 153.819 42.2324 153.547 42.2324Z" fill={color} />
            <path d="M63.5762 56.2388C63.3044 56.2388 63.0326 55.9641 63.0326 55.6895C63.0326 55.4149 62.7607 55.4149 62.7607 55.1403H63.5762C63.5762 55.4149 63.5762 55.4149 63.848 55.6895C63.848 55.6895 64.1198 55.9641 64.3916 55.9641C64.6635 55.9641 64.9353 55.9641 64.9353 55.6895C64.9353 55.6895 65.2071 55.4149 65.2071 55.1403C65.2071 54.8656 65.2071 54.8656 65.2071 54.8656L64.9353 54.591C64.9353 54.591 64.6635 54.591 64.3916 54.3164C64.1198 54.3164 63.848 54.0417 63.5762 54.0417C63.3044 54.0417 63.3044 53.7671 63.0326 53.7671C63.0326 53.4925 62.7607 53.4925 62.7607 53.2179C62.7607 52.9432 62.7607 52.6686 63.0326 52.6686C63.0326 52.394 63.3044 52.394 63.5762 52.1194C63.848 52.1194 64.1198 51.8447 64.3916 51.8447C64.9353 51.8447 65.2071 51.8447 65.4789 52.1194C65.7507 52.394 66.0225 52.6686 66.0225 52.9432H65.2071C65.2071 52.6686 65.2071 52.6686 64.9353 52.6686C64.9353 52.6686 64.6635 52.394 64.3916 52.394C64.1198 52.394 64.1198 52.394 63.848 52.6686C63.848 52.6686 63.5762 52.9432 63.5762 53.2179V53.4925L63.848 53.7671C63.848 53.7671 64.1198 53.7671 64.3916 54.0417C64.6635 54.0417 64.9353 54.3164 65.2071 54.3164C65.4789 54.3164 65.4789 54.591 65.7507 54.591C66.0225 54.591 66.0225 54.8656 66.0225 55.1403C66.0225 55.4149 66.0225 55.6895 65.7507 55.6895C65.7507 55.9641 65.4789 55.9641 65.2071 56.2388C64.9353 56.2388 64.6635 56.5134 64.3916 56.5134C64.1198 56.5134 63.848 56.2388 63.5762 56.2388Z" fill={color} />
            <path d="M68.1973 52.1191V56.5132H67.3818V52.1191H68.1973Z" fill={color} />
            <path d="M73.3611 56.5132H72.5456L70.3711 53.2176V56.5132H69.5557V52.1191H70.3711L72.5456 55.4147V52.1191H73.3611V56.5132Z" fill={color} />
            <path d="M74.9925 52.9432C75.2643 52.6686 75.5361 52.394 75.808 52.1194C76.0798 51.8447 76.6234 51.8447 76.8952 51.8447C77.4389 51.8447 77.7107 51.8447 77.9825 52.1194C78.2543 52.394 78.5261 52.6686 78.7979 53.2179H77.9825C77.9825 52.9432 77.7107 52.9432 77.4389 52.6686C77.167 52.6686 76.8952 52.394 76.6234 52.394C76.3516 52.394 76.0798 52.394 75.808 52.6686C75.5361 52.6686 75.5362 52.9432 75.2643 53.2179C75.2643 53.4925 74.9925 53.7671 74.9925 54.0417C74.9925 54.3164 74.9925 54.591 75.2643 54.8656C75.2643 55.1402 75.5361 55.4149 75.808 55.4149C76.0798 55.4149 76.3516 55.6895 76.6234 55.6895C76.8952 55.6895 77.167 55.6895 77.4389 55.4149C77.7107 55.4149 77.7107 55.1402 77.9825 54.8656H78.7979C78.5261 55.4149 78.2543 55.6895 77.9825 55.6895C77.7107 55.6895 77.167 55.9641 76.8952 55.9641C76.6234 55.9641 76.0798 55.9641 75.808 55.6895C75.5361 55.4149 75.2643 55.1402 74.9925 54.8656C74.7207 54.591 74.7207 54.0417 74.7207 53.7671C74.7207 53.4925 74.7207 53.4925 74.9925 52.9432Z" fill={color} />
            <path d="M81.2442 52.6684V54.0415H82.6032V54.5908H81.2442V55.9639H82.875V56.5132H80.4287V52.1191H82.875V52.6684H81.2442Z" fill={color} />
            <path d="M86.9521 52.394V51.8447H88.3112V56.5134H87.4958V52.6686H86.9521V52.394Z" fill={color} />
            <path d="M91.3015 55.6895C91.3015 55.6895 91.5733 55.9641 91.8451 55.9641C92.1169 55.9641 92.3887 55.9641 92.3887 55.6895C92.3887 55.4149 92.6605 55.1402 92.6605 54.591C92.6605 54.8656 92.3887 54.8656 92.1169 54.8656C91.8451 54.8656 91.8451 54.8656 91.5733 54.8656C91.3015 54.8656 91.0296 54.8656 90.7578 54.591C90.486 54.3164 90.486 54.3164 90.2142 54.0417C90.2142 53.7671 89.9424 53.4925 89.9424 53.2179C89.9424 52.6686 89.9424 52.394 90.2142 52.1194C90.486 51.8447 90.7578 51.8447 91.3015 51.8447C91.8451 51.8447 92.3887 52.1194 92.3887 52.394C92.3887 52.6686 92.6605 53.2179 92.6605 54.0417C92.6605 54.591 92.6605 55.1402 92.6605 55.4149C92.6605 55.6895 92.3887 55.9641 92.1169 56.2388C91.8451 56.5134 91.5733 56.5134 91.3015 56.5134C90.7578 56.5134 90.486 56.5134 90.2142 56.2388C89.9424 55.9641 89.9424 55.6895 89.9424 55.4149H90.486C91.0296 55.4149 91.0296 55.4149 91.3015 55.6895ZM92.3887 53.7671C92.6605 53.4925 92.6605 53.4925 92.6605 53.2179C92.6605 52.9432 92.6605 52.6686 92.3887 52.6686C92.1169 52.394 92.1169 52.394 91.8451 52.394C91.5733 52.394 91.3015 52.394 91.3015 52.6686C91.0296 52.9432 91.0296 52.9432 91.0296 53.2179C91.0296 53.4925 91.0296 53.7671 91.3015 53.7671C91.5733 54.0417 91.5733 54.0417 91.8451 54.0417C91.8451 54.0417 92.1169 54.0417 92.3887 53.7671Z" fill={color} />
            <path d="M95.9226 55.6895C95.9226 55.6895 96.1944 55.9641 96.4662 55.9641C96.738 55.9641 97.0098 55.9641 97.0098 55.6895C97.0098 55.4149 97.2816 55.1402 97.2816 54.591C97.2816 54.8656 97.0098 54.8656 96.738 54.8656C96.4662 54.8656 96.4662 54.8656 96.1944 54.8656C95.9225 54.8656 95.6507 54.8656 95.3789 54.591C95.1071 54.3164 95.1071 54.3164 94.8353 54.0417C94.8353 53.7671 94.5635 53.4925 94.5635 53.2179C94.5635 52.6686 94.5635 52.394 94.8353 52.1194C95.1071 51.8447 95.3789 51.8447 95.9226 51.8447C96.4662 51.8447 97.0098 52.1194 97.0098 52.394C97.0098 52.6686 97.2816 53.2179 97.2816 54.0417C97.2816 54.591 97.2816 55.1402 97.2816 55.4149C97.2816 55.6895 97.5534 55.9641 97.2816 56.2388C97.0098 56.5134 96.738 56.5134 96.4662 56.5134C95.9225 56.5134 95.6507 56.5134 95.3789 56.2388C95.1071 55.9641 95.1071 55.6895 95.1071 55.4149H95.6507C95.6507 55.4149 95.6507 55.4149 95.9226 55.6895ZM97.0098 53.7671C97.2816 53.4925 97.2816 53.4925 97.2816 53.2179C97.2816 52.9432 97.2816 52.6686 97.0098 52.6686C96.738 52.394 96.738 52.394 96.4662 52.394C96.1944 52.394 95.9226 52.394 95.9226 52.6686C95.6507 52.9432 95.6507 52.9432 95.6507 53.2179C95.6507 53.4925 95.6507 53.7671 95.9226 53.7671C96.1944 54.0417 96.1944 54.0417 96.4662 54.0417C96.4662 54.0417 96.738 54.0417 97.0098 53.7671Z" fill={color} />
            <path d="M99.1836 52.394V51.8447H100.543V56.5134H99.7272V52.6686H99.1836V52.394Z" fill={color} />
            <path d="M110.057 56.5132H105.979V55.6893H108.154C108.154 54.8654 107.882 54.3162 107.339 54.3162C107.067 54.3162 107.067 54.3162 106.795 54.3162V53.7669C107.067 53.7669 107.339 53.7669 107.339 53.7669H107.61C108.426 54.0415 108.969 54.5908 108.969 55.6893H110.057V56.5132ZM107.61 52.943H107.067V52.1191H107.61V52.943Z" fill={color} />
            <path d="M112.503 56.5132H109.785V55.6893H110.329C110.601 55.6893 110.601 55.6893 110.872 55.4147C110.872 55.4147 110.872 55.14 110.872 54.8654V53.4923H111.688V54.8654C111.688 55.14 111.688 55.4147 111.416 55.6893H112.503V56.5132ZM111.416 52.943H110.872V52.1191H111.416V52.943Z" fill={color} />
            <path d="M116.037 55.4148C116.037 56.2387 115.493 56.788 114.677 56.788C114.134 56.788 113.862 56.5134 113.59 56.2387H112.503V55.4148H113.318C113.318 55.4148 113.318 55.4149 113.318 55.1402C113.318 54.3163 113.862 53.7671 114.677 53.7671C115.493 53.7671 116.037 54.591 116.037 55.4148ZM115.221 55.4148C115.221 54.8656 114.949 54.591 114.406 54.591C114.134 54.591 113.862 54.8656 113.862 55.4148C113.862 55.9641 114.134 56.2387 114.677 56.2387C114.949 56.2387 115.221 55.9641 115.221 55.4148Z" fill={color} />
            <path d="M169.313 0.489258H9.21387V1.03851H169.313V0.489258Z" fill={color} />
            <path d="M169.313 19.7134H9.21387V20.2626H169.313V19.7134Z" fill={color} />
            <path d="M128.269 46.6265H50.2578V47.1757H128.269V46.6265Z" fill={color} />
        </svg>
    </>
}

export default StampSVG;