import React from "react";

const InstagramSVG: React.FC=()=> {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="33"
      fill="none"
      viewBox="0 0 33 33"
    >
      <path
        fill="#1077BC"
        d="M22.515 4.469a6.034 6.034 0 016.017 6.016v12.03a6.034 6.034 0 01-6.017 6.017h-12.03a6.034 6.034 0 01-6.016-6.017v-12.03a6.034 6.034 0 016.016-6.016h12.03zm0-2.407h-12.03c-4.633 0-8.422 3.79-8.422 8.423v12.03c0 4.633 3.79 8.422 8.422 8.422h12.03c4.633 0 8.422-3.79 8.422-8.422v-12.03c0-4.633-3.79-8.422-8.422-8.422z"
      ></path>
      <path
        fill="#1077BC"
        d="M24.32 10.484a1.805 1.805 0 110-3.61 1.805 1.805 0 010 3.61zm-7.82 1.203a4.812 4.812 0 110 9.624 4.812 4.812 0 010-9.624zm0-2.406a7.219 7.219 0 100 14.438 7.219 7.219 0 000-14.438z"
      ></path>
    </svg>
  );
}

export default InstagramSVG;
