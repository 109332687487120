const UserSettingsSVG: React.FC = () => {
    return <>
        <svg width="45" height="46" viewBox="0 0 45 46" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_3_4808)">
                <path d="M24.0814 0C18.2287 0 13.4443 4.78431 13.4443 10.637C13.4443 16.4898 18.2287 21.2741 24.0814 21.2741C29.9341 21.2741 34.7184 16.4898 34.7184 10.637C34.7184 4.78431 29.9341 0 24.0814 0Z" fill="#0D77BC" />
                <path d="M42.3353 29.7745C42.0566 29.0778 41.6851 28.4275 41.267 27.8236C39.1303 24.665 35.8324 22.5748 32.1164 22.0638C31.652 22.0174 31.141 22.1102 30.7694 22.389C28.8185 23.8289 26.496 24.5721 24.0806 24.5721C21.6651 24.5721 19.3427 23.8289 17.3918 22.389C17.0201 22.1102 16.5092 21.9709 16.0447 22.0638C12.3287 22.5748 8.98437 24.665 6.89415 27.8236C6.4761 28.4275 6.10447 29.1243 5.82583 29.7745C5.68651 30.0533 5.73292 30.3784 5.87224 30.6571C6.24388 31.3074 6.70833 31.9578 7.12638 32.5151C7.77665 33.3977 8.47342 34.1873 9.2631 34.9305C9.91337 35.5808 10.6566 36.1846 11.3998 36.7885C15.0693 39.5291 19.4821 40.9689 24.0342 40.9689C28.5863 40.9689 32.999 39.529 36.6685 36.7885C37.4117 36.2311 38.1549 35.5808 38.8053 34.9305C39.5484 34.1873 40.2916 33.3976 40.942 32.5151C41.4064 31.9113 41.8246 31.3074 42.1961 30.6571C42.4282 30.3784 42.4747 30.0532 42.3353 29.7745Z" fill="#0D77BC" />
            </g>
            <path fillRule="evenodd" clipRule="evenodd" d="M43.7425 33.584C43.0483 33.1813 42.6171 32.431 42.6171 31.625C42.6171 30.8195 43.0482 30.0692 43.7404 29.6668C44.4509 29.2555 44.7464 28.4009 44.4427 27.6349C44.0622 26.6672 43.5441 25.7635 42.9066 24.9483C42.3985 24.2993 41.5161 24.1293 40.8057 24.5433C40.1135 24.9483 39.2513 24.9496 38.5571 24.5464C37.8649 24.1432 37.4358 23.3917 37.4378 22.5843C37.4378 21.7583 36.8509 21.0729 36.0393 20.9547C35.0192 20.8059 33.9829 20.8067 32.9588 20.9577C32.1492 21.0767 31.5623 21.7618 31.5642 22.5861C31.5642 23.3925 31.1351 24.1436 30.443 24.5464C29.7487 24.9491 28.8886 24.9483 28.1944 24.5437C27.484 24.1297 26.6015 24.3005 26.0935 24.9496C25.7777 25.354 25.4863 25.7863 25.2293 26.2353C24.9722 26.6836 24.7475 27.1534 24.5573 27.6309C24.2517 28.3982 24.5472 29.254 25.2576 29.6664C25.9518 30.0688 26.3829 30.8195 26.3829 31.625C26.3829 32.4306 25.9518 33.1806 25.2596 33.5836C24.5492 33.995 24.2537 34.8492 24.5573 35.615C24.9378 36.5832 25.4559 37.487 26.0935 38.3017C26.6015 38.9507 27.484 39.1207 28.1944 38.7067C28.8866 38.3017 29.7488 38.3009 30.443 38.7036C31.1351 39.1068 31.5642 39.8584 31.5642 40.6657C31.5622 41.4915 32.1512 42.1769 32.9608 42.2954C33.4688 42.3694 33.9788 42.4063 34.4888 42.4063C35.007 42.4063 35.5251 42.3683 36.0412 42.2923C36.8508 42.173 37.4377 41.4886 37.4377 40.6643C37.4357 39.8575 37.8648 39.1064 38.557 38.7036C39.2512 38.3009 40.1114 38.3017 40.8056 38.7063C41.516 39.1203 42.3985 38.9499 42.9065 38.3005C43.2222 37.8958 43.5137 37.4635 43.7707 37.0151C44.0257 36.5669 44.2544 36.097 44.4427 35.6191C44.7484 34.8518 44.4529 33.9958 43.7425 33.584ZM37.2536 33.2265C36.8306 33.9673 36.1445 34.4974 35.3228 34.7188C34.5031 34.9401 33.6449 34.8266 32.9102 34.3986C31.3922 33.5155 30.87 31.5533 31.7464 30.0235C32.3334 28.9974 33.4061 28.423 34.5051 28.423C35.0455 28.423 35.5899 28.5608 36.0898 28.8515C37.6078 29.7343 38.13 31.6972 37.2536 33.2265Z" fill="#B7D7EB" />
            <defs>
                <clipPath id="clip0_3_4808">
                    <rect x="3.59375" width="40.9688" height="40.9688" rx="10" fill="white" />
                </clipPath>
            </defs>
        </svg>
    </>
}

export default UserSettingsSVG;