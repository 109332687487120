import React from 'react';

const SendSVG: React.FC=()=> {
    return (
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M23.8824 13.7693L2.19172 22.7149C2.13146 22.7432 2.06504 22.756 1.99858 22.7519C1.93211 22.7479 1.86772 22.7273 1.81131 22.6919C1.75491 22.6565 1.70829 22.6075 1.67573 22.5494C1.64318 22.4914 1.62573 22.426 1.625 22.3594V16.6054C1.625 16.5108 1.65803 16.4192 1.71838 16.3463C1.77873 16.2734 1.86263 16.2239 1.95559 16.2063L14.53 13.8196C15.4116 13.6525 15.4116 12.3901 14.53 12.223L1.95508 9.83628C1.8623 9.81857 1.77859 9.76908 1.71834 9.69633C1.6581 9.62357 1.6251 9.5321 1.625 9.43765V3.68616C1.625 3.39468 1.92359 3.16514 2.19172 3.27991L23.8824 12.2768C24.0282 12.3396 24.1525 12.4438 24.2397 12.5764C24.327 12.709 24.3735 12.8643 24.3735 13.0231C24.3735 13.1818 24.327 13.3371 24.2397 13.4697C24.1525 13.6023 24.0282 13.7065 23.8824 13.7693Z" stroke="#DA9230" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default SendSVG;
