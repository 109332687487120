import React from 'react';

const BriefcaseSVG: React.FC = () => {
    return (
        <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M26.1562 7.75H4.84375C3.23867 7.75 1.9375 9.05117 1.9375 10.6562V24.2188C1.9375 25.8238 3.23867 27.125 4.84375 27.125H26.1562C27.7613 27.125 29.0625 25.8238 29.0625 24.2188V10.6562C29.0625 9.05117 27.7613 7.75 26.1562 7.75Z" stroke="#0D77BC" strokeWidth="1.5" strokeLinejoin="round" />
            <path d="M8.71875 7.75V5.8125C8.71875 5.29864 8.92288 4.80583 9.28623 4.44248C9.64958 4.07913 10.1424 3.875 10.6562 3.875H20.3438C20.8576 3.875 21.3504 4.07913 21.7138 4.44248C22.0771 4.80583 22.2812 5.29864 22.2812 5.8125V7.75M29.0625 14.5312H1.9375M19.375 14.5312V15.9844C19.375 16.1128 19.324 16.236 19.2331 16.3269C19.1423 16.4177 19.0191 16.4688 18.8906 16.4688H12.1094C11.9809 16.4688 11.8577 16.4177 11.7669 16.3269C11.676 16.236 11.625 16.1128 11.625 15.9844V14.5312" stroke="#0D77BC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

export default BriefcaseSVG;
