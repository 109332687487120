import React from "react";

const FacebookSVG: React.FC=()=> {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="31"
      height="31"
      fill="none"
      viewBox="0 0 31 31"
    >
      <path
        stroke="#1077BC"
        strokeWidth="2"
        d="M30 15.594c0 6.878-4.724 12.64-11.078 14.187v-8.678h3.471l.129-.85.686-4.509.175-1.15h-4.462V12.67c0-.473.117-.817.3-1.03.159-.184.484-.407 1.227-.407h2.955v-5.68l-.83-.143-.17.985.17-.985h-.001 0-.002l-.005-.001-.018-.003-.065-.01a26.813 26.813 0 00-1.063-.145 22.217 22.217 0 00-2.486-.16c-1.974 0-3.716.606-4.965 1.863-1.249 1.258-1.89 3.053-1.89 5.204v2.436H8.142v6.509h3.936v8.676C5.724 28.232 1 22.47 1 15.594 1 7.529 7.498 1 15.5 1 23.502 1 30 7.529 30 15.594z"
      ></path>
    </svg>
  );
}

export default FacebookSVG;
