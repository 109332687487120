import React from "react";

const LinkedinSVG: React.FC=()=> {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="34"
      fill="none"
      viewBox="0 0 34 34"
    >
      <path
        fill="#fff"
        stroke="#1077BC"
        strokeWidth="1.5"
        d="M31.125 4.437v24.889c0 .996-.754 1.799-1.637 1.799H4.667c-.938 0-1.792-.861-1.792-1.8V4.443c0-.842.76-1.567 1.792-1.567h24.829c.97 0 1.634.666 1.629 1.562zM11.347 27.673h.75V12.922H6.335v14.751h5.012zm1.068-18.302v-.018c-.02-.81-.297-1.569-.836-2.132-.546-.57-1.31-.886-2.192-.886-.883 0-1.656.317-2.21.883-.55.562-.841 1.328-.841 2.153 0 1.647 1.181 3.036 2.997 3.036h.03c.897 0 1.672-.325 2.222-.896.546-.566.83-1.33.83-2.14zm14.508 18.302h.75v-8.491 0c0-2.117-.564-3.774-1.604-4.908-1.047-1.141-2.492-1.662-4.06-1.662-1.281 0-2.24.365-2.969.884l-.124.092v-.666h-5.762v14.751h5.762v-8.298c0-.376.035-.626.09-.758h0l.004-.01c.24-.602.722-1.101 1.489-1.101.547 0 .844.193 1.038.48.228.336.374.892.374 1.691v7.996h5.012z"
      ></path>
    </svg>
  );
}

export default LinkedinSVG;
