import React from "react";

const WhatsappSVG: React.FC=()=> {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="33"
      fill="none"
      viewBox="0 0 33 33"
    >
      <path
        fill="#1077BC"
        fillRule="evenodd"
        d="M26.73 6.258a14.317 14.317 0 00-10.17-4.195C8.638 2.063 2.187 8.48 2.184 16.37a14.22 14.22 0 001.92 7.155l-2.04 7.412 7.621-1.99a14.413 14.413 0 006.87 1.74h.007c7.923 0 14.373-6.419 14.377-14.308A14.191 14.191 0 0026.73 6.258zM16.56 28.273h-.005c-2.139 0-4.239-.572-6.081-1.658l-.437-.257-4.522 1.18 1.207-4.388-.284-.451A11.824 11.824 0 014.61 16.37C4.61 9.814 9.973 4.48 16.565 4.48a11.924 11.924 0 0111.945 11.9c-.002 6.558-5.363 11.893-11.95 11.893zm6.555-8.907c-.36-.179-2.127-1.044-2.455-1.163-.327-.12-.569-.18-.808.18-.239.358-.928 1.16-1.137 1.401-.21.242-.42.268-.778.089-.36-.18-1.518-.556-2.89-1.774-1.068-.949-1.788-2.12-1.998-2.477-.21-.357-.022-.552.157-.73.162-.16.36-.417.54-.626.18-.209.239-.358.358-.597.12-.238.06-.447-.03-.626-.089-.178-.808-1.938-1.107-2.654-.292-.698-.588-.603-.808-.614-.21-.01-.451-.013-.689-.013a1.323 1.323 0 00-.958.447c-.329.359-1.257 1.225-1.257 2.983 0 1.759 1.289 3.46 1.467 3.699.178.238 2.533 3.85 6.136 5.398.67.287 1.353.538 2.049.753.86.272 1.643.234 2.262.142.69-.103 2.127-.865 2.425-1.7.299-.836.3-1.551.21-1.7-.09-.149-.33-.24-.69-.418z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default WhatsappSVG;
