const NotificationsSVG: React.FC = () => {
    return <>
        <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M42.875 24.5C42.875 14.3555 34.6445 6.125 24.5 6.125C14.3555 6.125 6.125 14.3555 6.125 24.5C6.125 34.6445 14.3555 42.875 24.5 42.875C34.6445 42.875 42.875 34.6445 42.875 24.5Z" stroke="#1077BC" stroke-width="2.5" stroke-miterlimit="10" />
            <path d="M34.9511 29.9551C33.3883 28.1042 32.2848 27.3261 32.2848 22.2223C32.2848 17.5462 29.8176 15.8838 27.7867 15.0761C27.6467 15.0177 27.5204 14.9306 27.4159 14.8205C27.3114 14.7104 27.231 14.5797 27.18 14.4368C26.8249 13.2645 25.8287 12.25 24.5003 12.25C23.1719 12.25 22.1738 13.2645 21.8206 14.4378C21.7699 14.5807 21.6898 14.7115 21.5854 14.8216C21.4811 14.9317 21.3548 15.0187 21.2148 15.0771C19.1821 15.8867 16.7168 17.5462 16.7168 22.2232C16.7168 27.3271 15.6124 28.1051 14.0495 29.956C13.4016 30.7217 13.9873 32.1572 15.1204 32.1572H33.8792C35.0056 32.1562 35.5961 30.7207 34.9511 29.9551ZM21.078 33.6875C21.0241 33.6872 20.9708 33.6983 20.9215 33.72C20.8723 33.7417 20.8281 33.7735 20.792 33.8134C20.7559 33.8534 20.7286 33.9005 20.7119 33.9516C20.6952 34.0028 20.6895 34.057 20.6951 34.1105C20.9105 35.9021 22.5011 36.75 24.5003 36.75C26.4785 36.75 28.0547 35.8743 28.2959 34.1163C28.3024 34.0623 28.2974 34.0075 28.2811 33.9556C28.2648 33.9037 28.2376 33.8559 28.2014 33.8153C28.1652 33.7748 28.1207 33.7424 28.071 33.7203C28.0213 33.6983 27.9675 33.6871 27.9131 33.6875H21.078Z" fill="#1077BC" />
            <circle cx="31.5" cy="14.5" r="3.5" fill="#DF932D" />
        </svg>
    </>
}

export default NotificationsSVG;
