import React from "react";

const ChatbubbleSVG: React.FC = () => {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.63843 24.4922C5.71577 24.2099 5.54626 23.8174 5.38448 23.5344C5.33433 23.4499 5.27968 23.3681 5.22077 23.2895C3.83327 21.1853 3.09373 18.7201 3.09382 16.1996C3.07061 8.96478 9.06991 3.09375 16.4885 3.09375C22.9583 3.09375 28.3594 7.57582 29.6208 13.5255C29.8101 14.4076 29.9058 15.3072 29.9063 16.2093C29.9063 23.4545 24.1384 29.4177 16.7199 29.4177C15.5397 29.4177 13.9484 29.1212 13.0802 28.8782C12.212 28.6352 11.3451 28.313 11.1215 28.2266C10.8925 28.1387 10.6494 28.0935 10.4041 28.0932C10.1366 28.0922 9.87178 28.1455 9.6255 28.2498L5.253 29.8276C5.15745 29.8689 5.05617 29.8954 4.95265 29.9062C4.87076 29.906 4.78973 29.8895 4.71426 29.8578C4.63878 29.826 4.57035 29.7796 4.51293 29.7212C4.45551 29.6628 4.41024 29.5936 4.37973 29.5176C4.34922 29.4416 4.33408 29.3604 4.33518 29.2785C4.34056 29.2066 4.35352 29.1355 4.37386 29.0664L5.63843 24.4922Z"
        stroke="#1077BC"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M10.3125 18.5625C11.4516 18.5625 12.375 17.6391 12.375 16.5C12.375 15.3609 11.4516 14.4375 10.3125 14.4375C9.17341 14.4375 8.25 15.3609 8.25 16.5C8.25 17.6391 9.17341 18.5625 10.3125 18.5625Z"
        fill="#1077BC"
      />
      <path
        d="M16.5 18.5625C17.6391 18.5625 18.5625 17.6391 18.5625 16.5C18.5625 15.3609 17.6391 14.4375 16.5 14.4375C15.3609 14.4375 14.4375 15.3609 14.4375 16.5C14.4375 17.6391 15.3609 18.5625 16.5 18.5625Z"
        fill="#1077BC"
      />
      <path
        d="M22.6875 18.5625C23.8266 18.5625 24.75 17.6391 24.75 16.5C24.75 15.3609 23.8266 14.4375 22.6875 14.4375C21.5484 14.4375 20.625 15.3609 20.625 16.5C20.625 17.6391 21.5484 18.5625 22.6875 18.5625Z"
        fill="#1077BC"
      />
    </svg>
  );
};

export default ChatbubbleSVG;
